*, ::before, ::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}
html,body{
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body {
  margin: 0;
}

h1 {
  font-size: 2rem;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1.00rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

h2, h3, h4, h5, h6 {
  line-height: 1;
}

p, ul, ol, dl, address {
  line-height: 1.5;
}

pre {
  white-space: pre-wrap;
}

hr {
  border: .5px solid;
}

nav ul {
  list-style: none;
}

img, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}

img, video {
  max-width: 100%;
  height: auto;
}

img {
  border-style: none;
}

:root {
  line-sizing: normal;
  text-spacing: trim-start allow-end trim-adjacent ideograph-alpha ideograph-numeric;
}