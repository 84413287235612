$base-size: 16px !default; //- Default font size body,html

//- Layout
$container: 1280px !default; //- Page default container size
$container-padding: 0 15px !default; //- Page default container right-left padding size

//- Typography
$line-height: 1.5 !default;
$line-height-s: 1.3 !default;
$line-height-l: 1.7 !default;

//- Heading Title
$h1-title: 40px !default;
$h2-title: 32px !default;
$h3-title: 28px !default;
$h4-title: 24px !default;
$h5-title: 20px !default;
$h6-title: 16px !default;

//- Border Radius
$border-radius: 3px;

//- Margins
  //-  Global margin sizes, for mobile margin
$m: 15px;
$mtb: 15px 0 15px 0;
$mrl: 0 15px 0 15px;

//- Paddings
  //-  Global padding sizes, for mobile padding
$p: 15px;
$ptb: 15px 0 15px 0;
$prl: 0 15px 0 15px;

//- Colors
  //- Usage example: color(gray,light);
$color: (
  black: (
    'dark': #030303,
    'normal': #303030,
    'light': #2b2b2b,
  ),
  white: (
    'dark': #FFFFFF,
    'normal': #A8A8A8,
    'light': #636363,
  ),
  gray: (
    'dark': #C4CDD5,
    'normal': #DFE3E8,
    'light': #F4F6F8
  ),
  red: (
    'dark': #6D1212,
    'normal': #DD3333,
    'light': #F1ACAC,
  ),
  blue: (
    'dark': #000070,
    'normal': #0000FF,
    'light': #8F8FFF,
  ),
  yellow: (
    'dark': #7F6B01,
    'normal': #FEDA15,
    'light': #FFF09F,
  ),
  orange: (
    'dark': #793F02,
    'normal': #FC8A10,
    'light': #FECE9A,
  ),
  purple: (
    'dark':  #3C255B,
    'normal': #8155BA,
    'light':#CCBAE3,
  ),
  pink: (
    'dark':  #5B1041,
    'normal': #D62598,
    'light':#EE9BD1,
  ),
  cyan: (
    'dark': #007070,
    'normal': #00FFFF,
    'light':#8FFFFF,
  ),
  magenta: (
    'dark': #7A0051,
    'normal': #FF0BAC,
    'light': #FF99DC,
  ),
  green: (
    'dark': #008000,
    'normal': #0AFF0A,
    'light': #94FF94,
  ),
  lime: (
    'dark': #155714,
    'normal': #32CD30,
    'light': #A1E9A0,
  ),
  teal: (
    'dark': #23767B,
    'normal': #5DCAD1,
    'light': #CBEEF0,
  ),
  lavender: (
    'dark': #1D1443,
    'normal': #4B33AE,
    'light': #9E8EDE,
  ),
  brown: (
    'dark': #5F351D,
    'normal': #C57040,
    'light': #E5BFA9,
  ),
  beige: (
    'dark': #9B763B,
    'normal': #D5BA90,
    'light': #FCFAF8,
  ),
  maroon: (
    'dark': #27161B,
    'normal': #854B5B,
    'light': #C497A3,
  ),
  mint: (
    'dark': #0D8731,
    'normal': #32EC68,
    'light': #B4F8C8,
  ),
  olive: (
    'dark': #1E1C0B,
    'normal': #867E32,
    'light': #CDC579,
  ),
  navy: (
    'dark': #003366,
    'normal': #0078F0,
    'light': #7ABDFF,
  ),
);
@function color($color-name, $color-variant:null) {
  @if ($color-variant != null) {
    @return map-get(map-get($color, $color-name), $color-variant);
  } @else {
    @return map-get($color, $color-name);
  }
}

$selection: #0d0cb5;


// Grids
$grid-columns: 12 !default;
$xs-max: 40rem !default; 
$sm-max: 64rem !default; 
$md-max: 90rem !default; 
$lg-max: 120rem !default; 
$gutter: .5rem !default; 
$content-well-max-width: "none" !default; 
$sm-start: ($xs-max + 1);
$md-start: ($sm-max + 1);
$lg-start: ($md-max + 1);
$xl-start: ($lg-max + 1);
$breakpoint-xs-only: "only screen and (max-width: #{$xs-max})"; 
$breakpoint-sm-up: "only screen and (min-width: #{$sm-start})"; 
$breakpoint-sm-only: "only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})";
$breakpoint-md-up: "only screen and (min-width: #{$md-start})";
$breakpoint-md-only: "only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})";
$breakpoint-lg-up: "only screen and (min-width: #{$lg-start})";
$breakpoint-lg-only: "only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})";
$breakpoint-xl-up: "only screen and (min-width: #{$xl-start})";
$breakpoints-all: ($breakpoint-xs-only, $breakpoint-sm-up, $breakpoint-sm-only, $breakpoint-md-up, $breakpoint-md-only, $breakpoint-lg-up, $breakpoint-lg-only, $breakpoint-xl-up);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ("xs", "xs-only", "sm", "sm-only", "md", "md-only", "lg", "lg-only", "xl");
$breakpoint-up-prefixes: ("xs", "sm", "md", "lg", "xl");