h1, h2, h3, h4, h5, h6 {
  line-height: $line-height;
  margin: $m 0;
  font-weight: normal;
  small{
      font-size: 60%;
      display: block;
      color: color(black,light);
      opacity: .5;
  }
}
h1{
    @include font-size($h1-title);
}
h2{
    @include font-size($h2-title);
}
h3{
    @include font-size($h3-title);
}
h4{
    @include font-size($h4-title);
}
h5{
    @include font-size($h5-title);
}
h6{
    @include font-size($h6-title);
}

a {
    text-decoration: none;
    outline: 0;
    color: color(blue,normal);

    &:hover {
        color: color(black,normal);
        text-decoration: underline;
    }

    &:focus {
        color: color(black,normal);
        text-decoration: underline;
    }
}

b,
strong,
.bold {
    font-weight: bold;
}

em,
i,
.italic {
    font-style: italic;
}

strong,
b {
    font-weight: bold;
}

u,
ins {
    text-decoration: underline;
}

abbr {
    cursor: help;
}

var,
cite {
    font-style: italic;
    color: color(black,dark);
    opacity: .5;
}

cite {
    font-size: 12px;
    left: .05em;
    text-transform: uppercase;
}

kbd,
samp,
code {
    font-family: monospace;
}

kbd {
    font-size: 90%;
    padding: #{$p - 12px} #{$p - 9} #{$p - 12px} #{$p - 9};
    color: color(white,dark);
    border-radius: 2px;
    background: color(black,light);
}

code {
    vertical-align: middle;
    font-size: 90%;
    padding: #{$p - 12px} #{$p - 9} #{$p - 12px} #{$p - 9};
    color: color(black,light);
    border-radius: 2px;
    background: color(gray,normal);
}

sub,
sup {
    font-size: 80%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.3em;
}

sub {
    bottom: -.25em;
}

mark {
    padding: $p - 13px;
    background: color(yellow,normal);
}

small {
    font-size: font-size(12px);
}

address {
    font-style: italic;
}

del {
    color: color(red,normal);
}

ins {
    color: color(green,dark);
}

//- Blockquote
blockquote {
    font-style: italic;
    overflow: auto;
    p{
        margin: 0 !important;
    }
}

//- Content Pre
pre {
    @include font-size(16px);
    font-family:'Courier New', Courier, monospace;
    overflow: auto;
    padding: $p #{$p - 5px} $p #{$p - 5px};
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 1px solid color(gray,normal);
    border-radius: 2px;
    background: color(gray,light);
    code{
        background-color: color(gray,light);
    }
}

//- Description
dt {
    font-weight: bold;
}

dd {
    margin-left: $m - 5px;
    padding-left: $p - 5px;
    border-left: 3px solid color(gray,light);
}
//- Text class
.nowrap {
    white-space: nowrap;
    overflow: hidden;
}
.caps {
    text-transform: uppercase;
}
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
}
//- Text Position
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-justify {
    text-align: justify;
}
.text-inherit {
    text-align: inherit;
}
hr{
    border: 3px solid color(gray,light);
}