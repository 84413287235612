@mixin direction($direction: y, $positiv: true) {
	$positions: (-1: 17.25%, 0: 0, 1: 4.31%, 2: 8.61%, 3: 12.91%);
	$x: 0;
	$y: 0;
	$scale: 1;
	@each $i, $val in $positions {
		@if $direction == x {
			$x: $val;
		}
		@else {
			$y: $val;
		}

		@if $positiv == false and $i != 4 {
			$x: $x * -1;
			$y: $y * -1;
		}

		@if $i > 0 {
			$scale: $scale - 0.05;
		}

		@if $i == -1 {
			.cardslider__card {
        transform: scale(#{$scale - 0.2}) translate3d(#{$x}, #{$y}, 0);
        padding: 2rem;
			}
		}
		// @else if $i == 4 {
		// 	.cardslider__card--out {
		// 		transform: translate3d(#{$x}, #{$y}, 4px);
		// 	}
		// }
		@else {
			$scaleRule: '';
			@if $i != 0 {
				$scaleRule: scale(#{$scale});
			}
			.cardslider__card--index-#{$i} {
				transform: #{$scaleRule} translate3d(#{$x}, #{$y}, #{4-$i}px);
			}
		}
	}
}

.cardslider {
	width: 100%;
	height: 100%;
	position: relative;
	// overflow: hidden;


	&__cards,
	&__dots-nav {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__cards {
    width: 80%;
		height: 80%;
		position: absolute;
		left: 40%;
		top: 50%;
		transform: translate(-50%, -50%);
		transform-style: preserve-3d;
	}

	&__dots-nav {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}

	&__dot {
		display: inline-block;
		margin-right: 8px;
	}

	&__dot-btn {
		position: relative;
		border-radius: 50%;
		border: 2px solid white;
		width: 10px;
		height: 10px;
		display: block;
		appearance: none;
		background: none;
		padding: 0;

		&::before {
			content: ' ';
			position: absolute;
			display: block;
			background: white;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			transition: all .5s;
			transform: translate(-50%, -50%) scale(0);
		}
	}

	&__dot--active .cardslider__dot-btn::before {
		transform: translate(-50%, -50%) scale(1.1);
	}

	&__card {
		background: rgb(215, 215, 215);
		display: block;
		border-radius: 10px;
		width: 100%;
	  height: 100%;
		position: absolute;
		will-change: transform, opacity, background;
		transition: background 0.5s ease-out, opacity 0.5s;

		&--transitions {
			transition: background 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s;
		}

		&--index-0 {
			background: rgba(255, 255, 255, 1);
		}

		&--index-1 {
			background: rgba(255,255,255,0.4);
		}

		&--index-2 {
      // background: rgba(255,255,255,0);
      opacity: 0;
		}

		&--index-3 {
      // background: rgba(255,255,255,0.0);
      opacity: 0;
		}

		&--index-4 {
      // background: rgba(255,255,255,0.0);
      opacity: 0;
		}

		&--index-5 {
      // background: rgba(255,255,255,0.0);
      opacity: 0;
		}

		&--invisible {
			opacity: 0;
		}
	}

	&--direction-up {
		@include direction(y, true);
	}

	&--sortback-up {
		animation: sortback-up 0.67s;
	}

	&--sortfront-up {
		animation: sortfront-up 0.67s;
	}

	&--direction-down {
		@include direction(y, false);
	}

	&--sortback-down {
		animation: sortback-down 0.67s;
	}

	&--sortfront-down {
		animation: sortfront-down 0.67s;
	}

	&--direction-right {
		@include direction(x, false);
	}

	&--sortback-right {
		animation: sortback-right 0.67s;
	}

	&--sortfront-right {
		animation: sortfront-right 0.67s;
	}

	&--direction-left {
		@include direction(x, true);
	}

	&--sortback-left {
		animation: sortback-left 0.67s;
	}

	&--sortfront-left {
		animation: sortfront-left 0.67s;
	}

	&__direction-nav {
		position: absolute;
		left: 1rem;
		bottom: 3rem;
	}

	&__nav-next,
	&__nav-prev {
		display: inline-block;
		background: none;
		border: none;
		width: 40px;
		height: 40px;
		text-indent: -9999px;
		outline: none;
		transition: all .3s;
		// border: 2px solid white;
		// border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 30px 30px;
		padding: 0;

		&:active {
			transform: scale(.8);
		}
	}

	&__nav-next {
		margin-left: 10px;
		background-image: url('../img/icon-arrow-left.svg');
	}

	&__nav-prev {
		background-image: url('../img/icon-arrow-right.svg');
	}
}

@mixin anim() {

	$val: 100%;
	$directions: ('down', 'up', 'left', 'right');
	$steps: (0, 50, 60, 100);

	@each $dir in $directions {
		$x: 0;
		$y: 0;

		@if $dir == 'down' or $dir == 'up' {
			$y: $val;
		}
		@else {
			$x: $val;
		}

		@if $dir == 'up' or $dir == 'left' {
			$x: $x * -1;
			$y: $y * -1;
		}

		@keyframes sortback-#{$dir} {
			@each $step in $steps {
				$z: 0;
				$scale: 0;

				@if $step > 50 {
					$z: 0;
					$scale: 0.8;
				}
				@else {
					$z: 4px;
					$scale: 1;
				}

				$xval: 0;
				$yval: 0;

				@if $step > 0 and $step < 100 {
					$xval: $x;
					$yval: $y;
				}

				#{$step}% {
					transform: translate3d($xval, $yval, $z) scale($scale);
				}
			}
		}

		@keyframes sortfront-#{$dir} {

			@each $step in $steps {
				$z: 0;
				$scale: 0;

				@if $step < 60 {
					$z: 0;
					$scale: 0.8;
				}
				@else {
					$z: 4px;
					$scale: 1;
				}

				$xval: 0;
				$yval: 0;

				@if $step > 0 and $step < 100 {
					$xval: $x;
					$yval: $y;
				}

				#{$step}% {
					transform: translate3d($xval, $yval, $z) scale($scale);
				}
			}
		}
	}
}

@include anim();
