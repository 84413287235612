//-
//- 
//- Mixins
//-   example usage: @include mixin-name(XXXX);
//- 
//- Extend
//-   example usage: %extend extend-name(XXXX)
//- 
//- 

//============ Clearfix
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//============ Font Size
@function calculateRem($size) {
    $remSize: $size / $base-size;
    @return $remSize * 1rem;
}
@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

//============ Retina Image
@mixin img2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
        background-image: url($image);
        background-size: $width $height;
    }
}

//============ Image Cover
@mixin img-cover {
    min-height: 100%;
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

//============ Background Cover
@mixin bg-cover($url) {
    background: url($url) no-repeat center center fixed;
    background-size: cover;
}

//============ Vertical Center
@mixin vertical-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
@mixin  vertical-align ($height) {
    position: relative;
    &:before {
        display: table;
        height:$height;
    }
    > .inner-box {
        display: table-cell;
    }
}

//============ Arrow
@mixin arrow($direction: down, $size: 5px, $color: #555) {
    width: 0;
    height: 0;
    @if ($direction == left) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent; 
        border-right: $size solid $color;
    }
    @else if ($direction == right) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent; 
        border-left: $size solid $color;
    }
    @else if ($direction == down) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    }
    @else {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    }
}

//============ Ellipsis
@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//============ Text Shadow
@mixin text-shadow($x, $y, $blur, $color) {
    text-shadow: $x $y $blur $color;
}

//============ Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

//============ Background Gradient
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

//============ Center Block
@mixin center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//============ Grids
@mixin mobile { // Mobile
  @media screen and (max-width: $xs-max) {
    @content;
  }
}
@mixin tablet { // Tablet
  @media screen and (max-width: $sm-max) {
    @content;
  }
}
@mixin desktop { // Desktop
  @media screen and (max-width: $md-max) {
    @content;
  }
}
@mixin fullhd { // Full HD Desktop
  @media screen and (max-width: $lg-max) {
    @content;
  }
}

//============ Grid Mixins
@mixin row() {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  @if type-of($content-well-max-width) == number and $content-well-max-width != 0 and $full-width == "false"{
    max-width: $content-well-max-width;
  }
}

@mixin row-reverse() {
  flex-direction: row-reverse;
}

@mixin row-natural-height() {
  align-items: flex-start;
}

@mixin row-flex-column() {
  flex-direction: column;
}

@mixin col-reverse() {
  flex-direction: column-reverse;
}

@mixin col-condition($col-number) {
  @if type-of($col-number) == number {
    @if $col-number > $grid-columns or $col-number == 0 {
      @warn "Column number must be greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
    } @else {
      box-sizing: border-box;
      flex-basis: 100% / $grid-columns * $col-number;
      max-width: 100% / $grid-columns * $col-number;
      padding: $gutter;
    }
  } @else if $col-number == "auto" {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: $gutter;
  } @else {
    @warn "Column number argument must either be the string 'auto' or a number greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
  }
} 

@mixin col($breakpoint: "xs", $col-number: "auto") {
  @if type-of($breakpoint) == number {
    $col-number: $breakpoint;
    @include col-condition($col-number);
  } @else if $breakpoint == "xs" {
    @include col-condition($col-number);
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-condition($col-number);
    }
  } @else {
    @warn "col mixin requires one of the existing breakpoint prefixes (#{$breakpoint-up-prefixes})";
  }
}

@mixin col-offset-condition($offset-number) {
  @if type-of($offset-number) != number {
    @warn "Column offset number must be a number (only arg or second arg in the col mixin)";
  } @else if $offset-number == 0 {
    @warn "Column offset number must be greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
  } @else if $offset-number > $grid-columns {
    @warn "Column offset number must be greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
  } @else {
    margin-left: 100% / $grid-columns * $offset-number;
  }
} 

@mixin col-offset($breakpoint: "xs", $offset-number: 0) {
  @if type-of($breakpoint) == number {
    $offset-number: $breakpoint;
    @include col-offset-condition($offset-number);
  } @else if $breakpoint == "xs" {
    @include col-offset-condition($offset-number);
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-offset-condition($offset-number);
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      @include col-offset-condition($offset-number);
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-offset-condition($offset-number);
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-offset-condition($offset-number);
    }
  } @else {
    @warn "col mixin requires one of the existing breakpoint prefixes (#{$breakpoint-up-prefixes})";
  }
}

@mixin start($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    justify-content: flex-start;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      justify-content: flex-start;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      justify-content: flex-start;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      justify-content: flex-start;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      justify-content: flex-start;
    }
  } @else {
    @warn "start mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin center($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    justify-content: center;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      justify-content: center;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      justify-content: center;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      justify-content: center;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      justify-content: center;
    }
  } @else {
    @warn "center mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin end($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    justify-content: flex-end;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      justify-content: flex-end;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      justify-content: flex-end;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      justify-content: flex-end;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      justify-content: flex-end;
    }
  } @else {
    @warn "end mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin top($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    align-items: flex-start;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      align-items: flex-start;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      align-items: flex-start;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      align-items: flex-start;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      align-items: flex-start;
    }
  } @else {
    @warn "top mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin middle($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    align-items: center;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      align-items: center;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      align-items: center;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      align-items: center;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      align-items: center;
    }
  } @else {
    @warn "middle mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin bottom($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    align-items: flex-end;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      align-items: flex-end;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      align-items: flex-end;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      align-items: flex-end;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      align-items: flex-end;
    }
  } @else {
    @warn "bottom mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin around($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    justify-content: space-around;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      justify-content: space-around;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      justify-content: space-around;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      justify-content: space-around;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      justify-content: space-around;
    }
  } @else {
    @warn "around mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin between($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    justify-content: space-between;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      justify-content: space-between;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      justify-content: space-between;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      justify-content: space-between;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      justify-content: space-between;
    }
  } @else {
    @warn "between mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin first($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    order: -1;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      order: -1;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      order: -1;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      order: -1;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      order: -1;
    }
  } @else {
    @warn "first mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}

@mixin last($breakpoint: "xs") {
  @if $breakpoint == "xs" {
    order: 1;
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      order: 1;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      order: 1;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      order: 1;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      order: 1;
    }
  } @else {
    @warn "last mixin arg must be one of the existing breakpoints (#{$breakpoint-up-prefixes})";
  }
}
@mixin show($breakpoint, $row) {
  @if $breakpoint == "xs" {
    @if $row == "true" or $row == "row" {
      display: flex;
    } @else {
      display: block;
    }
  } @else if $breakpoint == "xs-only" {
    @media #{$breakpoint-xs-only} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "sm-only" {
    @media #{$breakpoint-sm-only} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "md-only" {
    @media #{$breakpoint-md-only} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "lg-only" {
    @media #{$breakpoint-lg-only} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      @if $row == "true" or $row == "row" {
        display: flex;
      } @else {
        display: block;
      }
    }
  } @else {
    @warn "show mixin arg must be one of the existing breakpoints or ranges (#{$breakpoint-all-prefixes})";
  }
}

@mixin hide($breakpoint) {
  @if $breakpoint == "xs" {
    display: none;
  } @else if $breakpoint == "xs-only" {
    @media #{$breakpoint-xs-only} {
      display: none;
    }
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      display: none;
    }
  } @else if $breakpoint == "sm-only" {
    @media #{$breakpoint-sm-only} {
      display: none;
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      display: none;
    }
  } @else if $breakpoint == "md-only" {
    @media #{$breakpoint-md-only} {
      display: none;
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      display: none;
    }
  } @else if $breakpoint == "lg-only" {
    @media #{$breakpoint-lg-only} {
      display: none;
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      display: none;
    }
  } @else {
    @warn "hide mixin arg must be one of the existing breakpoints or ranges (#{$breakpoint-all-prefixes})";
  }
}
