.button{
    display: inline-block;
    text-decoration: none;
    color: color(white,dark);
    background: color(black,dark);
    padding: #{$p - 4px} #{$p + 15px} #{$p - 4px} #{$p + 15px};
    margin: #{$p - 5px} 0 #{$p - 5px} 0;
    line-height: 13px;
    border-radius: $border-radius;
    @include font-size(13px);
    cursor: pointer;
    border:1px solid color(black,dark);
    outline: 0;
    &:hover{
        text-decoration: none;
        background: color(black,normal);
        color: color(white,dark);
        border:1px solid color(black,normal);
    }
    &:focus, &:active{
        box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.12);
        color: color(white,dark);
    }
    &--outline{
        background: color(white,dark);
        color: color(black,dark);
        &:hover{
            background: color(gray,light);
            color: color(black,light)
        }
        &:focus, &:active{
            color: color(black,dark);
        }
        &--small{
            @extend %button-small;
        }
        &--large{
            @extend %button-large;
        }
    }
    &--small{
        @extend %button-small;
    }
    &--large{
        @extend %button-large;
    }
}
%button-small{
    padding: #{$p - 7px} $p #{$p - 7px} $p;
}
%button-large{
    padding: #{$p + 3px} #{$p + 25px} #{$p + 3px} #{$p + 25px};
}