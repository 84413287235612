*, ::before, ::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
}

html, body {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
}

h1 {
  font-size: 2rem;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1.00rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

h2, h3, h4, h5, h6 {
  line-height: 1;
}

p, ul, ol, dl, address {
  line-height: 1.5;
}

pre {
  white-space: pre-wrap;
}

hr {
  border: .5px solid;
}

nav ul {
  list-style: none;
}

img, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}

img, video {
  max-width: 100%;
  height: auto;
}

img {
  border-style: none;
}

:root {
  line-sizing: normal;
  text-spacing: trim-start allow-end trim-adjacent ideograph-alpha ideograph-numeric;
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  color: #FFFFFF;
  background: #0078F0;
}

::selection {
  color: #FFFFFF;
  background: #0078F0;
}

figure img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.container {
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
}

.row {
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0.5rem;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0.5rem;
}

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
  padding: 0.5rem;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 16.66667%;
  max-width: 16.66667%;
  padding: 0.5rem;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 0.5rem;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 33.33333%;
  max-width: 33.33333%;
  padding: 0.5rem;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 41.66667%;
  max-width: 41.66667%;
  padding: 0.5rem;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0.5rem;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 58.33333%;
  max-width: 58.33333%;
  padding: 0.5rem;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 66.66667%;
  max-width: 66.66667%;
  padding: 0.5rem;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 0.5rem;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 83.33333%;
  max-width: 83.33333%;
  padding: 0.5rem;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 91.66667%;
  max-width: 91.66667%;
  padding: 0.5rem;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0.5rem;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 41rem) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0.5rem;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0.5rem;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0.5rem;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0.5rem;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0.5rem;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0.5rem;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0.5rem;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0.5rem;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .row.start-sm {
    justify-content: flex-start;
  }
  .row.center-sm {
    justify-content: center;
  }
  .row.end-sm {
    justify-content: flex-end;
  }
  .row.top-sm {
    align-items: flex-start;
  }
  .row.middle-sm {
    align-items: center;
  }
  .row.bottom-sm {
    align-items: flex-end;
  }
  .row.around-sm {
    justify-content: space-around;
  }
  .row.between-sm {
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 65rem) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0.5rem;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0.5rem;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0.5rem;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0.5rem;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0.5rem;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0.5rem;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0.5rem;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0.5rem;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .row.start-md {
    justify-content: flex-start;
  }
  .row.center-md {
    justify-content: center;
  }
  .row.end-md {
    justify-content: flex-end;
  }
  .row.top-md {
    align-items: flex-start;
  }
  .row.middle-md {
    align-items: center;
  }
  .row.bottom-md {
    align-items: flex-end;
  }
  .row.around-md {
    justify-content: space-around;
  }
  .row.between-md {
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 91rem) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0.5rem;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0.5rem;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0.5rem;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0.5rem;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0.5rem;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0.5rem;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0.5rem;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0.5rem;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .row.start-lg {
    justify-content: flex-start;
  }
  .row.center-lg {
    justify-content: center;
  }
  .row.end-lg {
    justify-content: flex-end;
  }
  .row.top-lg {
    align-items: flex-start;
  }
  .row.middle-lg {
    align-items: center;
  }
  .row.bottom-lg {
    align-items: flex-end;
  }
  .row.around-lg {
    justify-content: space-around;
  }
  .row.between-lg {
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 121rem) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0.5rem;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0.5rem;
  }
  .col-xl-offset-2 {
    margin-left: 16.66667%;
  }
  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0.5rem;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0.5rem;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333%;
  }
  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0.5rem;
  }
  .col-xl-offset-5 {
    margin-left: 41.66667%;
  }
  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0.5rem;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0.5rem;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333%;
  }
  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0.5rem;
  }
  .col-xl-offset-8 {
    margin-left: 66.66667%;
  }
  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0.5rem;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0.5rem;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333%;
  }
  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0.5rem;
  }
  .col-xl-offset-11 {
    margin-left: 91.66667%;
  }
  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0.5rem;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .row.start-xl {
    justify-content: flex-start;
  }
  .row.center-xl {
    justify-content: center;
  }
  .row.end-xl {
    justify-content: flex-end;
  }
  .row.top-xl {
    align-items: flex-start;
  }
  .row.middle-xl {
    align-items: center;
  }
  .row.bottom-xl {
    align-items: flex-end;
  }
  .row.around-xl {
    justify-content: space-around;
  }
  .row.between-xl {
    justify-content: space-between;
  }
  .first-xl {
    order: -1;
  }
  .last-xl {
    order: 1;
  }
}

.col-gutter-lr {
  padding: 0 0.5rem;
}

.col-no-gutter {
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
  margin: 15px 0;
  font-weight: normal;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-size: 60%;
  display: block;
  color: #2b2b2b;
  opacity: .5;
}

h1 {
  font-size: 40px;
  font-size: 2.5rem;
}

h2 {
  font-size: 32px;
  font-size: 2rem;
}

h3 {
  font-size: 28px;
  font-size: 1.75rem;
}

h4 {
  font-size: 24px;
  font-size: 1.5rem;
}

h5 {
  font-size: 20px;
  font-size: 1.25rem;
}

h6 {
  font-size: 16px;
  font-size: 1rem;
}

a {
  text-decoration: none;
  outline: 0;
  color: #0000FF;
}

a:hover {
  color: #303030;
  text-decoration: underline;
}

a:focus {
  color: #303030;
  text-decoration: underline;
}

b,
strong,
.bold {
  font-weight: bold;
}

em,
i,
.italic {
  font-style: italic;
}

strong,
b {
  font-weight: bold;
}

u,
ins {
  text-decoration: underline;
}

abbr {
  cursor: help;
}

var,
cite {
  font-style: italic;
  color: #030303;
  opacity: .5;
}

cite {
  font-size: 12px;
  left: .05em;
  text-transform: uppercase;
}

kbd,
samp,
code {
  font-family: monospace;
}

kbd {
  font-size: 90%;
  padding: 3px 6px 3px 6px;
  color: #FFFFFF;
  border-radius: 2px;
  background: #2b2b2b;
}

code {
  vertical-align: middle;
  font-size: 90%;
  padding: 3px 6px 3px 6px;
  color: #2b2b2b;
  border-radius: 2px;
  background: #DFE3E8;
}

sub,
sup {
  font-size: 80%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.3em;
}

sub {
  bottom: -.25em;
}

mark {
  padding: 2px;
  background: #FEDA15;
}

small {
  font-size: font-size(12px);
}

address {
  font-style: italic;
}

del {
  color: #DD3333;
}

ins {
  color: #008000;
}

blockquote {
  font-style: italic;
  overflow: auto;
}

blockquote p {
  margin: 0 !important;
}

pre {
  font-size: 16px;
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  overflow: auto;
  padding: 15px 10px 15px 10px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: 1px solid #DFE3E8;
  border-radius: 2px;
  background: #F4F6F8;
}

pre code {
  background-color: #F4F6F8;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 3px solid #F4F6F8;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
}

.caps {
  text-transform: uppercase;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-inherit {
  text-align: inherit;
}

hr {
  border: 3px solid #F4F6F8;
}

.button {
  display: inline-block;
  text-decoration: none;
  color: #FFFFFF;
  background: #030303;
  padding: 11px 30px 11px 30px;
  margin: 10px 0 10px 0;
  line-height: 13px;
  border-radius: 3px;
  font-size: 13px;
  font-size: 0.8125rem;
  cursor: pointer;
  border: 1px solid #030303;
  outline: 0;
}

.button:hover {
  text-decoration: none;
  background: #303030;
  color: #FFFFFF;
  border: 1px solid #303030;
}

.button:focus, .button:active {
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.12);
  color: #FFFFFF;
}

.button--outline {
  background: #FFFFFF;
  color: #030303;
}

.button--outline:hover {
  background: #F4F6F8;
  color: #2b2b2b;
}

.button--outline:focus, .button--outline:active {
  color: #030303;
}

.button--outline--small, .button--small {
  padding: 8px 15px 8px 15px;
}

.button--outline--large, .button--large {
  padding: 18px 40px 18px 40px;
}

.form .form-field .form-field--error {
  border-color: #DD3333;
}

.form .form-field .form-field--error:focus, .form .form-field .form-field--error:active {
  box-shadow: 0px 0px 0px 3px rgba(252, 43, 43, 0.1);
  border-color: #F1ACAC;
}

.form .form-field .form-field--error::placeholder {
  color: #6D1212;
}

.form .form-field .form-field--success {
  border-color: #32CD30;
}

.form .form-field .form-field--success:focus, .form .form-field .form-field--success:active {
  box-shadow: 0px 0px 0px 3px rgba(60, 252, 43, 0.14);
  border-color: #A1E9A0;
}

.form .form-field .form-field--success::placeholder {
  color: #008000;
}

.form .form-field {
  margin-bottom: 10px;
}

.form .form-field input[type="checkbox"] {
  float: left;
  margin-right: 5px;
}

.form label {
  display: block;
  margin-bottom: 5px;
}

.form input {
  display: block;
  border: 1px solid;
  vertical-align: middle;
  width: 100%;
}

.form input[type='text'], .form input[type='email'], .form input[type='password'], .form input[type='tel'], .form input[type='datetime-local'], .form input[type='date'], .form input[type='search'] {
  height: 40px;
  margin: 0 0 15px 0;
  border-color: #C4CDD5;
  border-radius: 3px;
  font-size: 14px;
  padding: 0 15px 0 15px;
}

.form input[type='text']::placeholder, .form input[type='email']::placeholder, .form input[type='password']::placeholder, .form input[type='tel']::placeholder, .form input[type='datetime-local']::placeholder, .form input[type='date']::placeholder, .form input[type='search']::placeholder {
  color: #303030;
}

.form input[type='text']:focus, .form input[type='text']:active, .form input[type='email']:focus, .form input[type='email']:active, .form input[type='password']:focus, .form input[type='password']:active, .form input[type='tel']:focus, .form input[type='tel']:active, .form input[type='datetime-local']:focus, .form input[type='datetime-local']:active, .form input[type='date']:focus, .form input[type='date']:active, .form input[type='search']:focus, .form input[type='search']:active {
  box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
  border-color: #7ABDFF;
}

.form select {
  height: 40px;
  margin-bottom: 15px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #C4CDD5;
  border-radius: 3px;
  font-size: 14px;
  font-size: 0.875rem;
  width: 100%;
}

.form select:focus, .form select:active {
  box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
  border-color: #7ABDFF;
}

.form input[type='radio'], .form input[type='checkbox'] {
  width: auto;
  display: inline;
  margin-right: 2px;
}

.form input:hover {
  border: 1px solid #d9d9d9;
}

.form textarea:hover {
  border: 1px solid #d9d9d9;
}

.form input:focus,
.form textarea:focus {
  border-color: #cbcbcb;
}

.form textarea {
  display: block;
  margin-bottom: 15px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #C4CDD5;
  border-radius: 3px;
  width: 100%;
  max-height: 150px;
  height: 100vh;
}

.form textarea:focus, .form textarea:active {
  box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
  border-color: #7ABDFF;
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 15px 0;
}

.table td {
  padding: 0 15px 0 15px;
}

.table td:before {
  display: block;
  margin-bottom: 0.75em;
  margin-left: 0;
}

.table th {
  background-color: #F4F6F8;
  font-weight: bold;
}

.table th,
.table td {
  border: 1px solid #DFE3E8;
  padding: 9px 15px 9px 15px;
  text-align: left;
}

@media screen and (max-width: 40rem) {
  .table thead tr {
    position: absolute;
    top: -9999em;
    left: -9999em;
  }
  .table tr {
    border: 1px solid #C4CDD5;
    display: block;
  }
  .table tr + tr {
    margin: 15px 0 0 0;
  }
  .table td {
    display: block;
    border: 0;
    border-bottom: 1px solid #C4CDD5;
    padding-left: 50%;
    padding-bottom: 0;
  }
  .table td:last-child {
    border-bottom: 0;
  }
  .table td:before {
    content: attr(data-label);
    display: inline-block;
    font-weight: bold;
    margin-left: -100%;
    width: 100%;
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.tabs label {
  background: #F4F6F8;
  border: 1px solid #C4CDD5;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1;
  margin-left: -1px;
}

.tabs label:first-of-type {
  margin-left: 0;
  border-top-left-radius: 3px;
}

.tabs label:last-of-type {
  border-top-right-radius: 3px;
}

.tabs__content {
  width: 100%;
  margin-top: -1px;
  padding: 15px;
  border: 1px solid #C4CDD5;
  -webkit-order: 1;
  order: 1;
  border-radius: 3px;
  border-top-left-radius: 0;
}

.tabs input[type=radio], .tabs__content {
  display: none;
}

.tabs input[type=radio]:checked + label {
  background: #FFFFFF;
  border-bottom: 0;
}

.tabs input[type=radio]:checked + label + .tabs__content {
  display: block;
}

.nav {
  margin-bottom: 15px;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  font-size: 1rem;
  color: black;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

.nav ul li ul {
  display: none;
}

.nav ul li:hover {
  cursor: pointer;
  background-color: #DFE3E8;
}

.nav ul li:hover ul {
  display: block;
  padding: 10px 0;
  width: 200px;
  left: 0;
  position: absolute;
}

.nav ul li:hover ul li {
  float: inherit;
  display: block;
  background-color: #F4F6F8;
}

.nav ul li:hover ul li:hover {
  background-color: #C4CDD5;
}

.cardslider {
  width: 100%;
  height: 100%;
  position: relative;
}

.cardslider__cards, .cardslider__dots-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardslider__cards {
  width: 80%;
  height: 80%;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
}

.cardslider__dots-nav {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.cardslider__dot {
  display: inline-block;
  margin-right: 8px;
}

.cardslider__dot-btn {
  position: relative;
  border-radius: 50%;
  border: 2px solid white;
  width: 10px;
  height: 10px;
  display: block;
  appearance: none;
  background: none;
  padding: 0;
}

.cardslider__dot-btn::before {
  content: ' ';
  position: absolute;
  display: block;
  background: white;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transition: all .5s;
  transform: translate(-50%, -50%) scale(0);
}

.cardslider__dot--active .cardslider__dot-btn::before {
  transform: translate(-50%, -50%) scale(1.1);
}

.cardslider__card {
  background: #d7d7d7;
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  will-change: transform, opacity, background;
  transition: background 0.5s ease-out, opacity 0.5s;
}

.cardslider__card--transitions {
  transition: background 0.5s ease-out, transform 0.5s ease-out, opacity 0.5s;
}

.cardslider__card--index-0 {
  background: white;
}

.cardslider__card--index-1 {
  background: rgba(255, 255, 255, 0.4);
}

.cardslider__card--index-2 {
  opacity: 0;
}

.cardslider__card--index-3 {
  opacity: 0;
}

.cardslider__card--index-4 {
  opacity: 0;
}

.cardslider__card--index-5 {
  opacity: 0;
}

.cardslider__card--invisible {
  opacity: 0;
}

.cardslider--direction-up .cardslider__card {
  transform: scale(0.8) translate3d(0, 17.25%, 0);
  padding: 2rem;
}

.cardslider--direction-up .cardslider__card--index-0 {
  transform:  translate3d(0, 0, 4px);
}

.cardslider--direction-up .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(0, 4.31%, 3px);
}

.cardslider--direction-up .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(0, 8.61%, 2px);
}

.cardslider--direction-up .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(0, 12.91%, 1px);
}

.cardslider--sortback-up {
  animation: sortback-up 0.67s;
}

.cardslider--sortfront-up {
  animation: sortfront-up 0.67s;
}

.cardslider--direction-down .cardslider__card {
  transform: scale(0.8) translate3d(0, -17.25%, 0);
  padding: 2rem;
}

.cardslider--direction-down .cardslider__card--index-0 {
  transform:  translate3d(0, 0, 4px);
}

.cardslider--direction-down .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(0, -4.31%, 3px);
}

.cardslider--direction-down .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(0, -8.61%, 2px);
}

.cardslider--direction-down .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(0, -12.91%, 1px);
}

.cardslider--sortback-down {
  animation: sortback-down 0.67s;
}

.cardslider--sortfront-down {
  animation: sortfront-down 0.67s;
}

.cardslider--direction-right .cardslider__card {
  transform: scale(0.8) translate3d(-17.25%, 0, 0);
  padding: 2rem;
}

.cardslider--direction-right .cardslider__card--index-0 {
  transform:  translate3d(0, 0, 4px);
}

.cardslider--direction-right .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(-4.31%, 0, 3px);
}

.cardslider--direction-right .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(-8.61%, 0, 2px);
}

.cardslider--direction-right .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(-12.91%, 0, 1px);
}

.cardslider--sortback-right {
  animation: sortback-right 0.67s;
}

.cardslider--sortfront-right {
  animation: sortfront-right 0.67s;
}

.cardslider--direction-left .cardslider__card {
  transform: scale(0.8) translate3d(17.25%, 0, 0);
  padding: 2rem;
}

.cardslider--direction-left .cardslider__card--index-0 {
  transform:  translate3d(0, 0, 4px);
}

.cardslider--direction-left .cardslider__card--index-1 {
  transform: scale(0.95) translate3d(4.31%, 0, 3px);
}

.cardslider--direction-left .cardslider__card--index-2 {
  transform: scale(0.9) translate3d(8.61%, 0, 2px);
}

.cardslider--direction-left .cardslider__card--index-3 {
  transform: scale(0.85) translate3d(12.91%, 0, 1px);
}

.cardslider--sortback-left {
  animation: sortback-left 0.67s;
}

.cardslider--sortfront-left {
  animation: sortfront-left 0.67s;
}

.cardslider__direction-nav {
  position: absolute;
  left: 1rem;
  bottom: 3rem;
}

.cardslider__nav-next, .cardslider__nav-prev {
  display: inline-block;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  outline: none;
  transition: all .3s;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
  padding: 0;
}

.cardslider__nav-next:active, .cardslider__nav-prev:active {
  transform: scale(0.8);
}

.cardslider__nav-next {
  margin-left: 10px;
  background-image: url("../img/icon-arrow-left.svg");
}

.cardslider__nav-prev {
  background-image: url("../img/icon-arrow-right.svg");
}

@keyframes sortback-down {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
  50% {
    transform: translate3d(0, 100%, 4px) scale(1);
  }
  60% {
    transform: translate3d(0, 100%, 0) scale(0.8);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-down {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
  50% {
    transform: translate3d(0, 100%, 0) scale(0.8);
  }
  60% {
    transform: translate3d(0, 100%, 4px) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

@keyframes sortback-up {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
  50% {
    transform: translate3d(0, -100%, 4px) scale(1);
  }
  60% {
    transform: translate3d(0, -100%, 0) scale(0.8);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-up {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
  50% {
    transform: translate3d(0, -100%, 0) scale(0.8);
  }
  60% {
    transform: translate3d(0, -100%, 4px) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

@keyframes sortback-left {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
  50% {
    transform: translate3d(-100%, 0, 4px) scale(1);
  }
  60% {
    transform: translate3d(-100%, 0, 0) scale(0.8);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-left {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
  50% {
    transform: translate3d(-100%, 0, 0) scale(0.8);
  }
  60% {
    transform: translate3d(-100%, 0, 4px) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

@keyframes sortback-right {
  0% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
  50% {
    transform: translate3d(100%, 0, 4px) scale(1);
  }
  60% {
    transform: translate3d(100%, 0, 0) scale(0.8);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
}

@keyframes sortfront-right {
  0% {
    transform: translate3d(0, 0, 0) scale(0.8);
  }
  50% {
    transform: translate3d(100%, 0, 0) scale(0.8);
  }
  60% {
    transform: translate3d(100%, 0, 4px) scale(1);
  }
  100% {
    transform: translate3d(0, 0, 4px) scale(1);
  }
}

header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

header .title, header .subtitle {
  padding: 0;
  margin: 0;
  line-height: normal;
  color: #45195E;
}

header .title {
  font-weight: 700;
  font-size: 2.6rem;
}

header .subtitle {
  font-weight: 200;
  font-size: 1.1rem;
}

header a {
  color: inherit;
}

header a:hover {
  text-decoration: none;
  color: #3c1652;
}

header .logo {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

header .logo a {
  display: block;
  width: 4rem;
  margin-left: auto;
}

header .logo a img {
  display: block;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 40rem) {
  header {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  header .row {
    flex-direction: column-reverse;
  }
  header .title, header .subtitle {
    text-align: center;
  }
  header .title {
    font-size: 2.4rem;
  }
  header .logo {
    transform: none;
    padding-bottom: 1.5rem;
  }
  header .logo a {
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {
  background-color: rgba(0, 0, 0, 0.25);
}

.footer .social-icons {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: right;
}

.footer .social-icons .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-left: 1rem;
  background-color: #fff;
  border-radius: 4px;
}

.footer .social-icons .icon:first-child {
  margin-left: 0;
}

.footer .social-icons .icon.icon-instagram {
  background-image: url(../img/icon-instagram.svg);
}

.footer .social-icons .icon.icon-facebook {
  background-image: url(../img/icon-facebook.svg);
}

.footer .social-icons .icon.icon-twitter {
  background-image: url(../img/icon-twitter.svg);
}

.footer .social-icons .icon.icon-email {
  background-image: url(../img/icon-email.svg);
  background-color: transparent;
}

@media only screen and (max-width: 40rem) {
  .footer {
    padding-top: 1rem;
    padding-bottom: 2rem;
    text-align: center;
  }
  .footer .social-icons {
    text-align: center;
  }
}

.nav {
  margin-bottom: 15px;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  font-size: 1rem;
  color: black;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

.nav ul li ul {
  display: none;
}

.nav ul li:hover {
  cursor: pointer;
  background-color: #DFE3E8;
}

.nav ul li:hover ul {
  display: block;
  padding: 10px 0;
  width: 200px;
  left: 0;
  position: absolute;
}

.nav ul li:hover ul li {
  float: inherit;
  display: block;
  background-color: #F4F6F8;
}

.nav ul li:hover ul li:hover {
  background-color: #C4CDD5;
}

section.lead {
  padding-bottom: 3rem;
}

section.lead .phone-container {
  position: relative;
}

section.lead .phone-container img {
  position: absolute;
  top: 0;
  z-index: 9999;
}

section.lead .lead-content p {
  font-size: 1rem;
  line-height: 1.4;
}

section.lead .download-buttons {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

section.lead .download-buttons a {
  display: inline-block;
  margin: 0.5rem;
}

section.lead .download-buttons a img {
  display: block;
  height: 40px;
  width: auto;
}

@media only screen and (max-width: 40rem) {
  section.lead {
    padding-bottom: 0;
  }
  section.lead .lead-content {
    text-align: center;
  }
  section.lead .download-buttons {
    text-align: center;
    padding-top: 1rem;
  }
  section.lead .download-buttons a {
    display: block;
  }
  section.lead .download-buttons a img {
    height: 50px;
    margin: 0 auto;
  }
  section.lead .phone-container {
    height: 340px;
    overflow: hidden;
    margin-top: 1rem;
  }
  section.lead .phone-container img {
    padding-left: 1rem;
  }
  section.lead .row {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 40rem) {
  body.ios section.lead .download-buttons a.android {
    display: none;
  }
}

@media only screen and (max-width: 40rem) {
  body.android section.lead .download-buttons a.ios {
    display: none;
  }
}

section.app-details {
  background-image: linear-gradient(135deg, #311044 0%, #4E1D6B 100%);
  color: #fff;
}

section.app-details .slider-container {
  height: 20rem;
  color: #000;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

section.app-details .cardslider__card span {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

section.app-details .cardslider__card span.icon {
  font-size: 3rem;
}

@media only screen and (max-width: 40rem) {
  section.app-details .slider-container {
    margin-top: 2rem;
    margin-bottom: 3rem;
    height: 16rem;
  }
  section.app-details .cardslider__cards {
    width: 100% !important;
    left: 50%;
  }
  section.app-details .cardslider__direction-nav {
    left: auto;
    right: 0;
    bottom: -1.5rem;
  }
}

section.contract .card-content .card-body h1 {
  font-size: 2rem;
}

section.contract .card-content .card-body h2 {
  font-size: 1.5rem;
}

section.contract .card-content .card-body h3 {
  font-size: 1rem;
}

section.contract .card-content .card-body p {
  font-size: 1rem;
  line-height: 1.5;
}

section.contract .card-content .card-body a {
  color: inherit;
  text-decoration: underline;
}
