//- Pug Example
//- 
//- .table
//-     thead
//-         th
//-             td Head 1
//-     tbody
//-         th
//-             td  Body 1
.table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 $m 0;

    td {
        padding:$mrl;
        &:before {
            display: block;
            margin-bottom: 0.75em;
            margin-left: 0;
        }
    }
    th {
        background-color: color(gray, light);
        font-weight: bold;
    }
    
    th,
    td {
        border: 1px solid color(gray, normal);
        padding: #{$p - 6px} $p #{$p - 6px} $p;
        text-align: left;
    }
    @include mobile {
        thead tr {
            position: absolute;
            top: -9999em;
            left: -9999em;
        }
    
        tr {
            border: 1px solid color(gray, dark);
            +tr {
                margin: $m 0 0 0;
            }
    
            display: block;
        }
    
        td {
            display: block;
            border: 0;
            border-bottom: 1px solid color(gray, dark);
            padding-left: 50%;
            padding-bottom: 0;
            &:last-child{
                border-bottom: 0;
            }
            &:before {
                content: attr(data-label);
                display: inline-block;
                font-weight: bold;
                margin-left: -100%;
                width: 100%;
            }
        }
    }
}