//- Pug Example
//- 
//- .tabs
//-     input(type="radio" id="tab1" name="tab1" checked="checked")
//-     label(for="tab1") Deneme
//-     .tabs__content
//-         | Tab 1
//-     input(type="radio" id="tab2" name="tab2" checked="checked")
//-     label(for="tab2") Deneme
//-     .tabs__content
//-         | Tab 2
.tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $m;
    label {
        background: color(gray, light);
        border: 1px solid color(gray, dark);
        padding: #{$p - 5px} $p;
        cursor: pointer;
        z-index: 1;
        margin-left: -1px;

        &:first-of-type {
            margin-left: 0;
            border-top-left-radius: $border-radius;
        }
        &:last-of-type{
            border-top-right-radius: $border-radius;
        }
    }

    &__content {
        width: 100%;
        margin-top: -1px;
        padding: $p;
        border: 1px solid color(gray, dark);
        -webkit-order: 1;
        order: 1;
        border-radius: $border-radius;
        border-top-left-radius: 0;
    }

    input[type=radio],
    &__content {
        display: none;
    }

    input[type=radio]:checked+label {
        background: color(white, dark);
        border-bottom: 0;

        +.tabs__content {
            display: block;
        }
    }
}