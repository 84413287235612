//- .nav
//-     ul
//-         li
//-             a Menu 1
.nav {
    margin-bottom: $m;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            font-size: 1rem;
            color: black;
            display: inline-block;
            padding: #{$p - 5px} $p;
            position: relative;

            ul {
                display: none;
            }

            &:hover {
                cursor: pointer;
                background-color: color(gray,normal);

                ul {
                    display: block;
                    padding: #{$p - 5px} 0;
                    width: 200px;
                    left: 0;
                    position: absolute;
                    li {
                        float: inherit;
                        display: block;
                        background-color: color(gray,light);
                        &:hover {
                            background-color: color(gray,dark);
                        }
                    }
                }
            }
        }
    }
}