section.contract {
    .card-content {
        .card-body {
            h1 {
                font-size: 2rem;
            }
            h2 {
                font-size: 1.5rem;
            }
            h3 {
                font-size: 1rem;
            }
            p {
                font-size: 1rem;
                line-height: 1.5;
            }
            a {
                color: inherit;
                text-decoration: underline;
            }

        }
    }
}