.footer {
    background-color: rgba(0,0,0,0.25);

    .social-icons {
        @include vertical-center;
        text-align: right;

        .icon {
            display: inline-block;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin-left: 1rem;
            background-color: #fff;
            border-radius: 4px;

            &:first-child {
                margin-left: 0
            }

            &.icon-instagram {
                background-image: url(../img/icon-instagram.svg);
            }

            &.icon-facebook {
                background-image: url(../img/icon-facebook.svg);
            }

            &.icon-twitter {
                background-image: url(../img/icon-twitter.svg);
            }

            &.icon-email {
                background-image: url(../img/icon-email.svg);
                background-color: transparent;
            }
        }

    }

    @media #{$breakpoint-xs-only} {
        padding-top: 1rem;
        padding-bottom: 2rem;
        text-align: center;

        .social-icons {
            text-align: center;
        }
    }
}