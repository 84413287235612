//- .form
//-   .form-field
//-     label(for="text")
//-     input(type="text" id="text" class="form-field--error form-field--success")
.form {
  %form-error{
    .form-field--error{
      border-color: color(red, normal);
      &:focus, &:active{
        box-shadow: 0px 0px 0px 3px rgba(252, 43, 43, 0.10);
        border-color: color(red, light);
      }
      &::placeholder{
        color: color(red, dark);
      }
    }
    .form-field--success{
      border-color: color(lime, normal);
      &:focus, &:active{
        box-shadow: 0px 0px 0px 3px rgba(60, 252, 43, 0.14);
        border-color: color(lime, light);
      }
      &::placeholder{
        color: color(green, dark);
      }
    }
  }
  .form-field{
    margin-bottom: $m - 5px;
    @extend %form-error;
    input[type="checkbox"]{
      float: left;
      margin-right:5px;
    }
  }
  label{
    display: block;
    margin-bottom: $m - 10px;
  }
  input {
    display: block;
    border: 1px solid;
    vertical-align: middle;
    width: 100%;
    &[type='text'],
    &[type='email'],
    &[type='password'],
    &[type='tel'],
    &[type='datetime-local'],
    &[type='date'],
    &[type='search'] {
      height: 40px;
      margin: 0 0 $m 0;
      border-color: color(gray, dark);
      border-radius: $border-radius;
      font-size: $base-size - 2px;
      padding: $prl;
      &::placeholder {
        color: color(black, normal);
      }
      &:focus,
      &:active {
        box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
        border-color: color(navy, light);
      }
    }
  }

  select {
    height: 40px;
    margin-bottom: $m;
    padding: #{$p - 10px} #{$p - 5} #{$p - 10px} #{$p - 5};
    border:1px solid color(gray, dark);
    border-radius: $border-radius;
    @include font-size(14px);
    width: 100%;
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
      border-color: color(navy, light);
    }
  }

  input {
    &[type='radio'],
    &[type='checkbox'] {
      width: auto;
      display: inline;
      margin-right: 2px;
    }

    &:hover {
      border: 1px solid #d9d9d9;
    }
  }

  textarea:hover {
    border: 1px solid #d9d9d9;
  }

  input:focus,
  textarea:focus {
    border-color: #cbcbcb;
  }

  textarea {
    display: block;
    margin-bottom: $m;
    padding: #{$p - 10px} #{$p - 5} #{$p - 10px} #{$p - 5};
    border:1px solid color(gray, dark);
    border-radius: $border-radius;
    width: 100%;
    max-height: 150px;
    height: 100vh;
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 3px rgba(0, 106, 206, 0.13);
      border-color: color(navy, light);
    }
  }
}