header {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .title, .subtitle {
        padding: 0;
        margin: 0;
        line-height: normal;
        color: #45195E;
    }
    .title {
        font-weight: 700;
        font-size: 2.6rem;
    }
    .subtitle {
        font-weight: 200;
        font-size: 1.1rem;
    }
    a {
        color: inherit;

        &:hover {
            text-decoration: none;
            color: darken(#45195E, 3%);
        }
    }

    .logo {
        @include vertical-center;
        a {
            display: block;
            width: 4rem;
            margin-left: auto;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }


    @media #{$breakpoint-xs-only} {
        padding-top: 2rem;
        padding-bottom: 1rem;

        .row {
            @include col-reverse;    
        }
        .title, .subtitle {
            text-align: center
        }
        .title {
            font-size: 2.4rem;
        }

        .logo {
            transform: none;
            padding-bottom: 1.5rem;

            a {
                margin-left: auto;
                margin-right: auto;
            }
        }
        
    }


}