section.app-details {
    background-image: linear-gradient(135deg, #311044 0%, #4E1D6B 100%);
    color: #fff;
    // border-top-left-radius: 100%;
    // border-top-right-radius: 100%;
    // border-top: 30px solid transparent;
    
    .slider-container {
        height: 20rem;
        color: #000;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .cardslider__card {
        span {
            display: block;
            font-size: 1rem;
            margin-bottom: 0.5rem;

            &.icon {
                font-size: 3rem;
            }
            &.text {

            }
        }
    }
    @media #{$breakpoint-xs-only} {
        .slider-container {
            margin-top: 2rem;
            margin-bottom: 3rem;
            height: 16rem;;
        }
        .cardslider__cards {
            width: 100%!important;
            left: 50%;
        }
        .cardslider__direction-nav {
            left: auto;
            right: 0;
            bottom: -1.5rem;
        }
    }

}