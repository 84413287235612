section.lead {
    padding-bottom: 3rem;

    .phone-container {
        position: relative;

        img {
            position: absolute;
            top: 0;
            z-index: 9999;
        }
    }
        
    .lead-content {
        p {
            font-size: 1rem;
            line-height: 1.4;
        }
    }
    .download-buttons {
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        a {
            display: inline-block;
            margin: 0.5rem;

            img {
                display: block;
                height: 40px;
                width: auto

            }
        }
    }

    @media #{$breakpoint-xs-only} {
        padding-bottom: 0;

        .lead-content {
            text-align: center;
        }
        .download-buttons {
            text-align: center;
            padding-top: 1rem;
            a {
                display: block;
                
                img {
                    height: 50px;
                    margin: 0 auto;
                }
            }
        }

        .phone-container {
            height: 340px;
            overflow: hidden;
            margin-top: 1rem;

            img {
                padding-left: 1rem;
            }
        }

        .row {
            @include col-reverse;    
        }    
    }
}

body.ios {
    section.lead {
        @media #{$breakpoint-xs-only} {
            .download-buttons {
                a.android {
                    display: none;
                }
            }
        
        }
    }
}

body.android {
    section.lead {
        @media #{$breakpoint-xs-only} {
            .download-buttons {
                a.ios {
                    display: none;
                }
            }
        
        }
    }
}