* {
    box-sizing: border-box;
}

::-moz-selection {
    color: color(white,dark);
    background: color(navy,normal);
}

::selection {
    color: color(white,dark);
    background: color(navy,normal);
}

figure img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.container {
    max-width: $container;
    padding: $container-padding;
    margin: 0 auto;
}